<template>
  <div class="container">
    <div class="form-card">
      <div class="card-body">
        <h4 class="card-sm-title">
          <i
            class="icon icon-arrow-left"
            @click="(step = 1), (notificationsText = 'self')"
            v-if="step == 2"
          ></i>
          Bildirim Oluştur
        </h4>
        <div class="row">
          <div class="col-md-8">
            <div class="generated-head" v-if="step == 2">
              <h5>
                <i class="icon icon-info-circle"></i>
                Bildirimi göndermeden önce sana bir önerimiz olacak!
              </h5>
              <p>
                Oluşturduğun bildirim başlığını ve bildirim metnini yapay zeka
                aracı kullanarak biraz düzenledik. Dilersen yapay zeka
                önerilerini kullanabilirsin.
              </p>
            </div>

            <TextTemplate
              label="Bildirim Adı"
              :value="formData.label"
              keyName="formData.label"
              type="text"
              @onChangeText="handleText"
              :className="v$.formData.label.$errors.length > 0 ? 'error' : ''"
              textInfo="Gönderilen bildirimler listesindeki görünen adıdır."
              v-if="step == 1"
            />
            <span
              v-for="error in v$.formData.label.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >

            <TextTemplate
              label="Yönlendirilecek Sayfa Linki"
              :value="formData.action_link"
              keyName="formData.action_link"
              type="text"
              @onChangeText="handleText"
              textInfo="Kullanıcının gönderilen bildirim tarafından yönlendirileceği sayfanın bağlantı linkidir."
              v-if="step == 1"
            />
            
            <UploadTemplate
              @file-committed="handleFileCommit"
              label="Bildirim Görseli"
              itemName="formData.image"
              textInfo="Bildirim’in yanında gözükecek olan görseldir. Nasıl göründüğünü önizleme kısmından inceleyebilirsin."
              v-if="step == 1"
            />
            <TextTemplate
              label="Bildirim Başlığı"
              :value="formData.title"
              keyName="formData.title"
              :className="v$.formData.title.$errors.length > 0 ? 'error' : ''"
              type="text"
              @onChangeText="handleText"
              textInfo="Kullanıcının göreceği bildirimin başlık kısmıdır. Net ve harekete geçirici bir başlık oluşturabilirsin."
              v-if="step == 1"
            />
            <span
              v-for="error in v$.formData.title.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >

            <template v-if="notificationsText == 'self'">
              <TextTemplate
                label="Bildirim Metni"
                :value="enteredData.message"
                keyName="enteredData.message"
                type="textarea"
                :className="v$.formData.message.$errors.length > 0 ? 'error' : ''"
                @onChangeText="handleText"
                textInfo="Kullanıcının okuyup bildirimin işlevini anlayacağı açıklama metnidir. Anlaşılır bir metin olması önerilir."
              />
              <button
                class="btn btn-empty-primary p-0"
                style="font-size: 14px"
                type="button"
                @click="setText('recommended')"
                v-if="step == 2"
              >
                <i class="icon icon-refresh mr-10" style="font-size: 14px"></i>
                Önerilen metni görüntüle
              </button>
            </template>
            <template v-if="notificationsText == 'recommended'">
              <TextTemplate
                label="Önerilen Bildirim Metni"
                :value="recommendedData.message"
                keyName="recommendedData.message"
                type="textarea"
                @onChangeText="handleText"
                :className="v$.formData.message.$errors.length > 0 ? 'error recommended-text' : 'recommended-text'"
                textInfo="Kullanıcının okuyup bildirimin işlevini anlayacağı açıklama metnidir. Anlaşılır bir metin olması önerilir."
              />
              <div class="d-flex">
                <button
                  class="btn btn-empty-primary p-0 mr-10"
                  style="font-size: 14px"
                  type="button"
                  @click="setText('self')"
                >
                  <i
                    class="icon icon-refresh mr-10"
                    style="font-size: 14px"
                  ></i>
                  Kendi yarattığım metni görüntüle
                </button>
                <button
                  class="btn btn-empty-primary p-0"
                  style="font-size: 14px"
                  type="button"
                  @click="createNewText"
                >
                  <i
                    class="icon icon-refresh mr-10"
                    style="font-size: 14px"
                  ></i>
                  Yeni Bir Metin Öner
                </button>
              </div>
            </template>
            
            <span
              v-for="error in v$.formData.message.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >

            <div class="generated-foot mt-30" v-if="step == 2">
              <h5>
                <i class="icon icon-message-question"></i>
                Neden önerilenleri kullanmalısın?
              </h5>
              <p>
                Yapay zeka, oluşturduğun metni daha harekete geçirici ve
                kullanıcıya hitap eden bir hale getirir. Önerilen metni
                kullanarak daha fazla kullanıcıya etki edebilirsin.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="notification-summary">
              <h5 class="summary-title">Bildirim Önizlemesi</h5>
              <div class="summary-tab">
                <button
                  class="btn btn-empty-dark"
                  :class="device == 'ios' ? 'active' : ''"
                  @click="device = 'ios'"
                >
                  IOS'da gör
                </button>
                <button
                  class="btn btn-empty-dark"
                  :class="device == 'android' ? 'active' : ''"
                  @click="device = 'android'"
                >
                  Android'de gör
                </button>
              </div>
              <div :class="device">
                <img
                  :src="'/media/' + device + '-screen.png'"
                  alt=""
                  class="device-img"
                />
                <div class="notification-content">
                  <h3>
                    {{
                      formData.title ||
                      "lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    }}
                  </h3>
                  <p>
                    {{ formData.message || "Bildirim Mesajı" }}
                  </p>
                  <img
                    :src="uploadedİmageUrl || '/media/image-not-found.jpeg'"
                    alt=""
                    class="notification-img"
                  />
                </div>
              </div>
            </div>
            <div class="mt-20">
              <button
                class="btn btn-filled-primary w-100"
                @click="nextStep"
                v-if="step == 1"
              >
                Kaydet ve Devam Et
              </button>

              <button
                class="btn btn-filled-primary w-100"
                @click="sendNotification"
                v-if="step == 2"
              >
                Bildirim Gönder
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    @click="closeModal"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      step: 1,
      enteredData: {
        message: "",
      },
      recommendedData: {
        message: "",
      },
      formData: {
        label: "",
        title: "",
        message: "",
        action_link: "",
        image: "",
      },
      generated: false,
      device: "ios",
      uploadedİmageUrl: "",
      user: this.$store.state.auth.user,
      appId: this.$route.params.id,
      notificationsText: "self",
    };
  },
  validations() {
    return {
      formData: {
        label: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        title: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        message: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
      },
    };
  },

  watch: {
    "enteredData.message": function () {
      this.formData.message = this.enteredData.message;
    },
    "recommendedData.message": function () {
      this.formData.message = this.recommendedData.message;
    },
  },
  created() {
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Bildirim Oluştur",
      },
    ]);
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    nextStep() {
      this.v$.formData.$touch();
      if (!this.v$.formData.$error) {
        this.step = 2;
      }
    },
    createNewText() {
      this.generateText();
    },
    async generateText() {
      //const apiText = `As Metin, a skilled digital marketer, you specialize in crafting compelling and innovative marketing copies for e-commerce businesses. Your objective is to write push notification message in a creative and unique way to drive sales and conversion, while maintaining a casual tone. The brand name that you should use is Test Customer. All responses should ALWAYS be in Turkish and no longer than 30 words. Please refer to ${this.enteredData.message} for push notification message context.`;
      const apiText = `As Metin, a skilled digital marketer, you specialize in crafting compelling and innovative marketing copies for e-commerce businesses. Your objective is to write push notification message in a creative and unique way to drive sales and conversion, while maintaining a casual tone. All responses should ALWAYS be in Turkish and no longer than 30 words. Please refer to ${this.enteredData.message} for push notification message context.`;

      const apiKey = "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";
      const apiUrl = "https://api.openai.com/v1/chat/completions";

      await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: apiText }],
          temperature: 0.7,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let generatedMessage = data.choices[0].message.content;

          this.recommendedData.message = generatedMessage;
        })
        .catch((error) => {
          console.error("API isteği başarısız oldu:", error);
        });
    },
    async setText(value) {
      this.notificationsText = value;
      if (value == "self") {
        this.formData.message = this.enteredData.message;
      } else {
        if (!this.generated) {
          this.generated = true;
          this.generateText();
        }
        this.formData.message = this.recommendedData.message;
      }
    },

    async sendNotification() {
      const postData = { ...this.formData };

      if (this.formData.image) {
        await this.convertBase64(this.formData.image).then(
          (resolveBase64WithName) => {
            postData.image = resolveBase64WithName;
          }
        );
      }

      this.$axios
        .post(`/mobile-app/${this.appId}/notification/`, postData)
        .then((response) => {
          this.popupText = "Bildirim gönderildi";
          this.popupTitle = "Bildirim";
          this.isCloseModal = true;
          setTimeout(() => {
            this.$router.push('/apps/app-list')
          }, 2000);
        });
    },
    convertBase64(file) {
      return new Promise((resolve, reject) => {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const base64String = e.target.result.split(",")[1];
            const resolveBase64WithName = `data:${
              file.type == "" ? "text/plain" : file.type
            };'${file.name}';base64,${base64String}`;

            resolve(resolveBase64WithName);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        } else {
          reject(new Error("No file provided"));
        }
      });
    },
    handleFileCommit(data) {
      this.uploadedİmageUrl = data.fileUrl;
      let keynames = data.name.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)

      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.file; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          target = target[keynames[i]]; // Yolu takip et
        }
      }
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)

      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.value; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          target = target[keynames[i]]; // Yolu takip et
        }
      }
    },
  },
};
</script>